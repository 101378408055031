@import '@aurora/shared-client/styles/_variables.pcss';

.lia-lock-icon {
  margin-left: 5px;
  flex-shrink: 0;
  margin-top: 4px;
  align-self: flex-start;
}

.lia-title-and-icon {
  display: flex;
  align-items: center;

  @media (--lia-breakpoint-down-sm) {
    justify-content: space-between;
  }
}
